/* eslint-disable eqeqeq */
import { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import api from "../../api";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
// import { Upload, message, Button } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "../../api_constants";
import axios from "axios";
import $ from "jquery";
import URLS from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

const Careers = () => {
  const [careerList, setCareerList] = useState([]);
  const [singleCareer, setSingleCareer] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [jobType, setJobType] = useState("");
  const [fileName, setFileName] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  // const [userAuth, setUserAuth] = useState(false);
  const [setUserAuth] = useState(false);

  useEffect(() => {
    // api.career
    //   .getCareer()
    //   .then((result) => {
    //     // console.log("get career ", result);
    //     if (result.status) {
    //       setCareerList(result.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    getCareerList();
  }, []);

  // GET CAREER LIST
  const getCareerList = () => {
    let URL = URLS.getcareers;
    fetch(URL)
      .then((response) => response.json())
      .then((data) => {
        // console.log('data',data);
        setCareerList(data);
      });
  };

  const applyForCareer = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append('full_name', fullName); 
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("job_type", jobType);
    formData.append("resume", fileName);
    formData.append("job_title", singleCareer.job_title);

    let url = API_BASE_URL + "/web/careers/apply";

    if (fullName == "") {
      alert("Please enter the full name", 3000);
      return;
    } else if (email == "") {
      alert("Please enter the email address", 3000);
      return;
    } else if (phone == "" || phone.length != "10") {
      alert("Please enter the valid Phone number", 3000);
      return;
    } else if (jobType == "") {
      alert("Please enter the job type", 3000);
      return;
    } else if (fileName == "") {
      alert("Please enter the resume.", 3000);
      return;
    } else if (captchaValue === "") {
      alert("Please fill recaptcha.", 3000);
      return;
    } 
    // else if (userAuth === false) {
    //   alert("Your are not Human", 3000);
    //   return;
    // }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log('response',response);
       
        if(response.data.status == '1'){
          alert("Job applied successfully.");
          $("#career-modal").attr({
            style: "display:none",
            "aria-hidden": "true",
          });
          $(".modal-backdrop").css("display", "none");
          $("body").removeClass("modal-open");
          setTimeout(()=> {
            window.location.reload();
          },2000);
        } else {
          alert("Please try again..");
        }
      })
      .catch((error) => {
        alert("Please try again..");
      });
  }

  const onChange = (e) => {
    setJobType(e.target.value);
  };

  const handleChangeResume = (event) => {
    // console.log('event',event.target.files[0]);
    // console.log('file name',event.target.files[0].name);
    setFileName(event.target.files[0]);
  };

  // var parse = require('html-react-parser');
  // var des = singleCareer.description && singleCareer.description.replace(/<[^>]+>/g, "")
  // var descrip = parse(des)

  const verifyToken = (value) => {
   // let url = `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_SECRET_KEY}&response=${value}`;
   // console.log('url',url);
    axios
      .post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${process.env.REACT_APP_SECRET_KEY}&response=${value}`
      )
      .then(
        (response) => {
          console.log('response',response);
          setUserAuth(response.data.success);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <Fragment>
      <Helmet>
        <title>Careers at AlgoAnalytics | Explore Opportunities in AI, ML, and Data Science</title>
        <meta
          name='description'
          content='Join AlgoAnalytics and work on cutting-edge AI, ML, and Deep Learning technologies. Explore career and internship opportunities in Data Science, Cloud Engineering, and more.'
        />
        <meta 
          name='keywords'
          content='Careers in AI, Machine Learning Jobs, Data Scientist Opportunities; Secondary Keywords: Work culture at AlgoAnalytics, Quantum computing roles, Full-stack developer jobs'
        />
      </Helmet>
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <Navigation />
      {/* <!-- Hero Section Start  --> */}
      <div
        className='career-hero-section section-bottom serv-page-hero padding-top career-bg'
        id=''>
        <div className='container '>
          <div className='serv-text text-center mb-5 position-relative mt-2 career-page-main-heading'>
            <h1>Build Your Career with AlgoAnalytics - Innovators in AI and ML</h1>
          </div>
          <div className='career-content'>
            <div className='career-inner row align-items-center'>
              <div className='career-left col-lg-6 col-md-12'>
                <div className='career-left-img'>
                  <img
                    src='assets/images/career-img1.png'
                    alt='img'
                    className='imf-fluid'
                  />
                </div>
              </div>
              <div className='career-right col-lg-6 col-md-12'>
                <div className='career-head'>
                Join AlgoAnalytics to shape the future of AI and ML. Explore rewarding careers and internships in cutting-edge technologies that redefine industries.
                </div>
              </div>
            </div>
            <div className='career-inner row flex-row-reverse align-items-center'>
              <div className='career-left col-lg-6 col-md-12'>
                <div className='career-right-img justify-content-end'>
                  <img
                    src='assets/images/career-img2.png'
                    alt='img'
                    className='imf-fluid'
                  />
                </div>
              </div>
              <div className='career-right col-lg-6 col-md-12'>
                <div className='career-des'>
                  <h2 style={{textAlign:"left"}}>Why Work at AlgoAnalytics?</h2>
                  <p className='text-left'>
                    We are always on the lookout for passionate and
                    self-motivated people to join our growing teams. Primary
                    requirements are good quantitative aptitude with excellent
                    academic background along with keen interest in cutting edge
                    technologies.
                  </p>
                  <p className='mb-5 text-left'>
                    {" "}
                    At AlgoAnalytics our employees get the opportunity to build
                    expertise in application of end-to-end AI/ML across
                    different industries.
                  </p>
                  <a href='#findAtalgo' className='btn-all btn-light-blue'>
                  Current Job Openings
                  </a>
                </div>
              </div>
            </div>
            <div className='career-inner row align-items-center text-left'>
              <div className='career-left col-lg-6 col-md-12'>
                <div className='image-container d-flex flex-wrap'>
                  <div className='image-text bg-purple-light'>
                    <span>We are</span>
                    <h4 className='color-purple-light'>Passionate!</h4>
                  </div>
                  <div className='image-text bg-green-light'>
                    <span>We are</span>
                    <h4 className='color-green-light'>Curious!</h4>
                  </div>
                  <div className='image-text bg-purple-dark'>
                    <span>We are</span>
                    <h4 className='color-purple-dark'>Innovative!</h4>
                  </div>
                  <div className='image-text bg-purple-light'>
                    <span>We are</span>
                    <h4 className='color-purple-light'>Rewarding!</h4>
                  </div>
                </div>
              </div>
              <div className='career-right col-lg-6 col-md-12'>
                <div className='career-des'>
                  <h2 style={{textAlign:"left"}}>Life at AlgoAnalytics - What Makes Us Unique?</h2>
                  <p className='mb-3 text-left'>
                    At AlgoAnalytics, we believe in loving the work we do! We
                    trust our people to deliver solutions that provide lasting
                    change. Collaborating, sharing ideas and welcoming
                    perspectives from all irrespective of your role in the
                    organization is the Algo way - and that's what makes us who
                    we are.
                  </p>
                  <p className='text-left'>
                    Our employees get the opportunity to build expertise in
                    application of end-to-end AI/ML across different industries.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Hero Section End  --> */}
      {/* <!--find section start--> */}
      <div className='find-main-container' id="findAtalgo">
        <div className='find-section section'>
          <div className='container '>
            <div className='serv-text text-center mb-5 position-relative'>
              <h2>Explore Career Opportunities</h2>
            </div>
            <div className='row find-top text-left'>
              <div className='col-lg-6 col-md-12'>
                <div className='s-title'>Full-Time Roles</div>
                <div className='find-sec-top'>
                  <div className='sub-para'>
                    <p>
                      At Algoanalytics, we believe in loving the work we do.
                      Connect with us to discover what it's like to work and
                      grow here with our remuneration being fiercely performance
                      based and at par with the industry standards.
                    </p>
                  </div>
                </div>

                <div className='mt-3'>
                  <h4>Featured Job Openings</h4>
                </div>

                <div className='find-sec-bottom'>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>Data Scientist(ML/GenAI/Quantum Computing)</span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>LLM Engineer</span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>Big Data / Cloud Engineer</span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>Full Stack Engineer</span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>MLOps/DevOps Engineer</span>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12'>
                <div className='s-title'>Internship Program Details</div>
                <div className='find-sec-top'>
                  <div className='sub-para'>
                    <p>
                      We believe in the long term rewarding prospects in Deep
                      Learning with hands-on experience in innovative AI
                      products development. We focus on offering the best
                      AI/ML/DL/Cloud practical applications with learning.
                    </p>
                    {/* <ul>
                      <li> 6 to 24 months full time internship</li>
                      <li>Assigned to specific project under the guidance of expert mentor
                      </li>
                      <li>Completion Certification and Pre-Placement Offer (PPO) for exceptional performance
                      </li>
                      <li>Assistance in publication in International Papers, Referrals, LinkedIn endorsements and much more</li>
                    </ul> */}
                  </div>
                </div>

                <div className='find-sec-bottom'>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>6 to 24 months full time internship</span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>
                      Assigned to specific project under the guidance of expert
                      mentor
                    </span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>
                      Completion Certification and Pre-Placement Offer (PPO) for
                      exceptional performance
                    </span>
                  </div>
                  <div className='t-text'>
                    {" "}
                    <img
                      src='assets/images/tick.png'
                      alt='img'
                      className='img-fluid'
                    />
                    <span>
                      Assistance in publication in International Papers,
                      Referrals, LinkedIn endorsements and much more
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='find-bottom text-left'>
              <div className='s-title'>Current Job Openings</div>
              <div className='row '>
                {careerList.map((data, index) => {
                  return (
                    <div
                      className='col-lg-6 col-md-12'
                      onClick={() => {
                        setSingleCareer(data);
                      }}
                      key={index}>
                      <div
                        className='ft-box'
                        data-toggle='modal'
                        data-target='#career-modal'>
                        <div className='ft-box-left text-left'>
                          <div className='sec-small-head'>{data.job_title}</div>
                          <p>{data.experience_needed} years experience</p>
                        </div>
                        <div className='ft-box-right'>
                          <div>
                            <img
                              src='assets/images/a.png'
                              alt='img'
                              className='imf-fluid'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="col-lg-6 col-md-12">
                                    <div className="ft-box" data-toggle="modal" data-target="#career-modal">
                                        <div className="ft-box-left">
                                            <div className="sec-small-head">
                                                Data Scientist
                                            </div>
                                            <p>2+ years experience</p>
                                        </div>
                                        <div className="ft-box-right">
                                            <a ><img src="assets/images/a.png" alt="image" className="imf-fluid" /></a>
                                        </div>
                                    </div>
                                </div> */}
                {/* <div className="col-lg-6 col-md-12">
                                    <div className="ft-box" data-toggle="modal" data-target="#career-modal">
                                        <div className="ft-box-left">
                                            <div className="sec-small-head">
                                                Big Data / Cloud Engineer
                                            </div>
                                            <p>2+ years experience</p>
                                        </div>
                                        <div className="ft-box-right">
                                            <a ><img src="assets/images/a.png" alt="image" className="imf-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="ft-box" data-toggle="modal" data-target="#career-modal">
                                        <div className="ft-box-left">
                                            <div className="sec-small-head">
                                                Machine Learning Specialist
                                            </div>
                                            <p>2+ years experience</p>
                                        </div>
                                        <div className="ft-box-right">
                                            <a ><img src="assets/images/a.png" alt="image" className="imf-fluid" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="ft-box" data-toggle="modal" data-target="#career-modal">
                                        <div className="ft-box-left">
                                            <div className="sec-small-head">
                                                Full Stack Engineer
                                            </div>
                                            <p>2+ years experience</p>
                                        </div>
                                        <div className="ft-box-right">
                                            <a ><img src="assets/images/a.png" alt="image" className="imf-fluid" /></a>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--find section end--> */}
      {/* <!--glimpse section start--> */}
      <div className='life-section section-bottom'>
        <div className='container'>
          <div className='life-inner row align-items-center'>
            <div className='slider-left col-md-12 col-lg-3'>
              <h2 className='sub-heading'>
              Discover the Algo Way
              </h2>
            </div>
            <div className='slider-right col-md-12 col-lg-9'>
              <div className='career-slider'>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img1.png'
                    alt= "Algo Analytics team pillars working together on a project"
                    className='img-fluid'
                  />
                </div>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img2.png'
                    alt="Team discussing ideas and strategies"
                    className='img-fluid'
                  />
                </div>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img1.png'
                    alt='CEO Aniruddha Pant in a planning session'
                    className='img-fluid'
                  />
                </div>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img2.png'
                    alt='Team collaborating on projects'
                    className='img-fluid'
                  />
                </div>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img1.png'
                    alt='CEO Aniruddha Pant in a planning session'
                    className='img-fluid'
                  />
                </div>
                <div className='career-slider-img'>
                  <img
                    src='assets/images/career-slider-img2.png'
                    alt='Team collaborating on projects'
                    className='img-fluid'
                  />
                </div>
              </div>
              <div className='slick-arrows career-arrow d-flex align-items-center'>
                <button className='slick-arrow-left slick-arrow'>
                  <svg
                    width='27'
                    height='22'
                    viewBox='0 0 27 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M12.2308 1.91748L3 11L12.2308 20.0824M27 11.0563L4.84615 11.0563'
                      stroke='#86909F'
                      stroke-width='3'
                    />
                  </svg>
                </button>
                <button className='slick-arrow-right slick-arrow'>
                  <svg
                    width='27'
                    height='22'
                    viewBox='0 0 27 22'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M12.2308 1.91748L3 11L12.2308 20.0824M27 11.0563L4.84615 11.0563'
                      stroke='#86909F'
                      stroke-width='3'
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--glimpse section end--> */}
      {/* <!--bottom section start--> */}
      <div className='bottom-section section-bottom'>
        <div className='container'>
          <div className='career-bottom-text'>
            Head on to Resources to know all about the <br />
            cutting-edge technologies we work on!
          </div>
          <a href='/resources' className='btn-all btn-light-blue mx-auto'>
            Visit Resources{" "}
          </a>
        </div>
      </div>
      {/* <!--bottom section end--> */}
      <Footer />
      {/* <!--bottom button--> */}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='back-top' href='#'>
        <i className='fa fa-angle-up'></i>
      </a>
      {/* <!--career modal--> */}
      <div
        className='modal career-modal'
        id='career-modal'
        data-keyboard='false'
        data-backdrop='static'>
        <div className='modal-dialog'>
          <div
            className='modal-content'
            style={{ position: "absolute", top: 0 }}>
            {/* <!-- Modal Header --> */}
            <div className='modal-header'>
              <h4 className='modal-title'>
                {singleCareer.job_title && singleCareer.job_title}
              </h4>
              <button type='button' className='close' data-dismiss='modal'>
                <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M13.9968 10.9664L8.65832 5.62818C7.8236 4.79061 6.46861 4.79061 5.62818 5.62818C4.79061 6.46576 4.79061 7.82074 5.62818 8.65831L10.9666 13.9965L5.6339 19.3327C4.79632 20.1703 4.79632 21.5252 5.6339 22.3628C6.05126 22.783 6.60011 22.9917 7.14897 22.9917C7.69782 22.9917 8.24667 22.783 8.66403 22.3628L13.9968 17.0266L19.3381 22.3711C19.7555 22.7913 20.3043 23 20.8532 23C21.3992 23 21.9509 22.7913 22.3682 22.3711C23.2058 21.5335 23.2058 20.1785 22.3682 19.341L17.0269 13.9965L22.3625 8.66432C23.2001 7.82674 23.2001 6.47176 22.3625 5.63418C21.525 4.79661 20.17 4.79661 19.3324 5.63418L13.9968 10.9664Z'
                    fill='#86909F'
                  />
                </svg>
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div className='modal-body'>
              <div className='career-modal-container'>
                <div className='career-modal-inner text-left'>
                  <div className='job-info'>
                    <h4>About the role</h4>
                    <p>
                      {/* <div style={{ fontSize: '20', fontFamily: 'normal' }}> */}
                      {/* <iframe srcDoc={`<style>p{	font-weight: normal;
font-size: 20px;
line-height: 28px;
white-space: pre
color: #141414;}</style><p>${des}</p>`} scrolling="no" id="myiFrame" style={{

                          border: "none"
                        }} width='700' height='250' /> */}
                      {/* {parse(singleCareer.description && singleCareer.description.toString())} */}
                      {/* {singleCareer.description} */}
                      {parse(String(singleCareer.description))}
                      {/* {des} */}
                      {/* </div> */}
                    </p>
                  </div>
                  <div className='job-info'>
                    <h4>Prerequisites</h4>
                    <ul className='job-list'>
                      <li>
                        {/* <iframe srcDoc=
                          {`<style>p{	font-weight: normal;
                          font-size: 20px;
                          line-height: 28px;
                          color: #141414;}</style> <p>${singleCareer.prerequisite && singleCareer.prerequisite.replace(/<[^>]+>/g, "")}</p>`}
                          scrolling="no" style={{ border: "none" }} height='50' id='myiFrame' /> */}
                        {parse(String(singleCareer.prerequisite))}
                      </li>
                    </ul>
                  </div>
                  <div className='job-btn mt-4'>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className='btn-all btn-light-blue mx-auto'>Apply</a>
                  </div>
                </div>
                <div className='career-form-content'>
                  <form className='career-form'>
                    <div className='form-top'>
                      <h4 className='form-title text-left'>
                        Enter Details: <span>*</span>
                      </h4>
                      <div className='c-form-input'>
                        <input
                          type='text'
                          className='f-input'
                          placeholder='Full Name'
                          value={fullName}
                          onChange={(e) => setFullName(e.target.value)}
                        />
                      </div>
                      <div className='c-form-input'>
                        <input
                          type='text'
                          className='f-input'
                          placeholder='Email Address'
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className='c-form-input'>
                        <input
                          type='text'
                          className='f-input'
                          placeholder='Phone Number'
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='form-top'>
                      <h4 className='form-title text-left'>Select Type:</h4>
                      <div className='c-form-input'>
                        <div className='f-radio d-flex align-items-center'>
                          <div className='mr-4'>
                            <input
                              type='radio'
                              id='test1'
                              name='jobType'
                              value='full-time'
                              checked={jobType == "full-time" ? true : false}
                              onChange={onChange}
                            />
                            <label for='test1'>Full-time Opportunity</label>
                          </div>
                          <div className=''>
                            <input
                              type='radio'
                              id='test2'
                              name='jobType'
                              value='internship'
                              onChange={onChange}
                              checked={jobType == "internship" ? true : false}
                            />
                            <label for='test2'>Internship Opportunity</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='form-top'>
                      <div className='upload-inputs'>
                        <div>
                          <h4 className='form-title text-left'>
                            Upload Resume:<span>*</span>
                          </h4>
                          <div className='d-flex mr-auto'>
                            <input
                              type='file'
                              style={{ display: "block" }}
                              onChange={handleChangeResume}
                            />
                          </div>
                        </div>
                        <div className="rcaptcha">
                          <ReCAPTCHA
                            sitekey='6LdCP4EeAAAAAH_QbmAxLPUPtaob9gGHmyPQ2XRd'
                            onChange={(value) => {
                              verifyToken(value);
                              setCaptchaValue(value);
                            }}
                          // size='invisible'
                          />
                        </div>
                      </div>
                      {/* <div className="upload-images-main align-items-center d-flex justify-content-center">
                        <input type="file" name="fileToUpload" id="upload" />
                        <label for="upload">
                          <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-2"
                          >
                            <path
                              d="M5.37983 9.51609C5.86026 6.66592 8.31586 4.5 11.2494 4.5C13.4503 4.5 15.2902 5.62023 16.3656 7.4505C18.976 7.64931 21 9.55701 21 12.1641C21 14.8607 18.8453 16.9362 16.1177 16.9362H15.0857V15.3934H16.1177C18.0312 15.3934 19.4571 13.971 19.4571 12.1641C19.4571 10.3813 18.01 8.97034 15.8995 8.97622L15.4205 8.97755L15.2069 8.54874C14.421 6.97104 12.9925 6.04286 11.2494 6.04286C8.96893 6.04286 7.04838 7.83178 6.85379 10.1492L6.80903 10.6823L6.29404 10.8272C5.24123 11.1235 4.54286 12.0088 4.54286 13.1006C4.54286 13.7977 4.79837 14.3527 5.21847 14.7385C5.64333 15.1286 6.2903 15.3934 7.14549 15.3934H8.91429V16.9362H7.14549C5.96935 16.9362 4.9293 16.5676 4.17495 15.8749C3.41585 15.1778 3 14.2007 3 13.1006C3 11.4702 3.96003 10.1179 5.37983 9.51609Z"
                              fill="#86909F"
                            />
                            <path
                              d="M9.96078 14.4044L11.2535 13.2112V19.6925H12.7963V13.2406L14.0571 14.4044L15.1036 13.2707L12.009 10.4141L8.91429 13.2707L9.96078 14.4044Z"
                              fill="#86909F"
                            />
                          </svg>

                          <span>
                            Drag and drop your file or <a href="#">Browse</a> to
                            upload
                          </span>
                        </label>
                      </div> */}

                      <div className='submit-btn mt-5'>
                        <button
                          type='submit'
                          onClick={applyForCareer}
                          className='btn-all btn-light-blue mx-auto '>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Careers;
