import { Fragment } from "react";
import Footer from "../Footer/Footer";
import Navigation from "../Navigation/Navigation";
import $ from "jquery";
import { useEffect } from "react";
import ContactUs from "../Common/ContactUs";
import { Helmet } from "react-helmet";

const EmergingTech = () => {
  useEffect(() => {
    $(".loader").slideUp("slow");
    $("body").addClass("animate");
  }, []);

  const EmergingTechData = [
    {
      title: "Advanced Digital Twin Solutions",
      h1: "Digital Twin Solutions",
      point: {
        point1: "Strong past work/experience in components/building blocks of Digital Twins",
        point2: "Digital Twin solutions built on platforms such as Nvidia Omniverse, Azure Digital Twin",
        point3: "Design Optimization of electrical circuit boards and devices",
        point4: "Mechanical Systems Design Optimization",
        point5: "Digital Twin of a Gas Turbine using Response Surface Method",
        point6: "Multiple CFD and FEM Simulations",
      },
      image: "assets/images/Emerging_Tech1.png",
      altText:"Digital Twin Solutions Image",
      imageOnLeft: true,
    },
    {
      title: "Custom Federated Learning Models",
      h1: "Federated Learning Services",
      point: {
        point1: "We develop custom federated learning models that are tailored to your business needs.",
        point2: "We deploy the developed models on your infrastructure and ensure that they are working as expected.",
        point3: "Solution development using Nvidia's NVFlare framework",
      },
      image: "assets/images/Emerging_Tech2.png",
      altText:"Federated Learning Models Image",
      imageOnLeft: false,
    },
    {
      title: "Pioneering Quantum Computing Solutions",
      h1: "Quantum Computing Solutions",
      point: {
        point1: "First in India to leverage quantum computers for tackling high-end complex problems in optimization and scheduling within the banking and financial services domain.",
        point2: "Pioneering the development of custom business solutions using Quantum Computing (QC).",
        point3: "Expertise in Proof of Concept (POC) development utilizing QC for specific use cases, showcasing the potential of quantum computing in addressing real-world challenges.",
        
      },
      image: "assets/images/Emerging_Tech3.png",
      altText:"Quantum Computing Solutions Image",
      imageOnLeft: true,
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Emerging Tech | Cutting-edge Emerging Technology Solutions</title>
        <meta
          name='description'
          content="Explore our comprehensive emerging technology services, including Digital Twin, Federated Learning, and Quantum Computing solutions."
        />
        <meta
          name='keywords'
          content='Emerging Tech, Digital Twin, Federated Learning, Quantum Computing'
        />
      </Helmet>
      <div className='loader'>
        <div className='loader-inner'>
          <img
            src='assets/images/loader.gif'
            alt='Loader GIF'
            className='loader-img'
            loop='infinite'
          />
        </div>
      </div>
      <Navigation />
      {/* <!-- Hero Section Start  --> */}
      <div className='common-main-section serv-page-hero serv-bg' id='emergingtech'>
        <div className='container h-100'>
          <div className='common-page-hero-section'>
            <div className='common-page-hero-title'>Emerging Tech</div>
            <div className='common-page-hero-subtitle'>
              We stay at the forefront of new and emerging technology areas and
              build expertise to deliver cutting-edge solutions to our clients.
            </div>
          </div>
          {EmergingTechData.map((data) => {
            return (
              <div className='item-grid' style={{paddingTop:"8rem",}} id={data.title}>
                {/* Image */}
                <div
                  className={`${
                    data.imageOnLeft === false ? "image-order" : ""
                  }`}>
                  <img
                    src={data.image}
                    alt= {data.altText}
                    className='item-image object-cover opacity-1'
                  />
                </div>
                {/* Text section */}
                <div className='item-text h-full py-4 text-left mx-8 '>
                  {/* title part */}
                  <div className='item-heading'>
                    <div className='item-title mb-9 '>{data.title}</div>
                  </div>
                  {/* <div className='item-subtitle mb-9'>{data.text}</div> */}
                  {/* text part */}
                  <div className='mb-9 opacity-70'>
                    <ul>
                      {Object.values(data.point).map((point, index) => (
                        <li key={index} className='main-list'>
                          {point}
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Button part */}
                  {/* <Link to={data.button.link}>
                    <ButtonIconBorder
                      name={data.button.name}
                      svgPathAttributes={{
                        fill: "currentColor",
                        d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                      }}
                      styleClasses='justify-start'
                    />
                  </Link> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <ContactUs />
      <Footer />
      
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className='back-top' href='#'>
        <i className='fa fa-angle-up'></i>
      </a>
    </Fragment>
  );
};
export default EmergingTech;
